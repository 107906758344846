import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import type { MessageDto } from '@repo/test-lib/message-dto';

export const api = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({ baseUrl: process.env.NEXT_PUBLIC_API_ORIGIN }),
  tagTypes: ['data'],
  refetchOnFocus: true,
  endpoints: builder => ({
    read: builder.query<MessageDto, void>({
      query: () => ({ url: `/messages` }),
      providesTags: ['data'],
    }),
    write: builder.mutation<void, MessageDto>({
      query: dto => ({ url: `/messages`, method: 'POST', body: dto }),
      invalidatesTags: ['data'],
    }),
  }),
});

export const { useReadQuery, useWriteMutation } = api;
